import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Sketch from '../../components/sketch';
import sketch from '../../scripts/archive/sGridworld';
import Layout from '../../components/layout';
import DateConverter from '../../components/dateConverter';
import 'katex/dist/katex.min.css';
export const _frontmatter = {
  "title": "Markov decision processes",
  "date": "2020-02-09",
  "slug": "mdps",
  "author": "Jan Malte Lichtenberg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Layout mdxType="Layout">
      <div>
        <DateConverter frontmatter={props.pageContext.frontmatter} mdxType="DateConverter" />
        <p className="blogtitle">
          {props.pageContext.frontmatter.title}
        </p>
      </div>
      <p>{`Many reinforcement learning (RL) algorithms are defined on Markov decision processes (or MDPs). More precisely, the problem that the RL agent is trying to solve
is often formulated as an MDP. In this mini-post, I try to answer the following questions.`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`What are MDPs? Or `}
          <em {...{
            "parentName": "li"
          }}>{`Who is Markov?`}</em>
          {` and what exactly is a decision process?`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`How are MDPs defined and do I really have to learn all of this if I am just interested in learning the basics of RL?`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`When is a MDP formulation insufficient? What are the alternatives?`}</li>

      </ul>
      <p>{`What are these MDPs?`}</p>
    </Layout>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      